import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Avatar,
  Chip,
  createFilterOptions,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import { Dropdown } from "@mui/base/Dropdown";
import { Menu } from "@mui/base/Menu";
import { MenuButton as BaseMenuButton } from "@mui/base/MenuButton";
import { MenuItem as BaseMenuItem, menuItemClasses } from "@mui/base/MenuItem";
import { CssTransition } from "@mui/base/Transitions";
import { PopupContext } from "@mui/base/Unstable_Popup";
import { useState } from "react";
import { SmallAvatar } from "./CreditCheckerBox";
import { capitalize } from "../../utility/Formater";
import { useEffect } from "react";
import {
  Add,
  Check,
  Clear,
  Delete,
  Edit,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { UserContext } from "../../Context/UserContext";
import { useContext } from "react";
import moment from "moment";
import useSocket from "../../hooks/useSocket";
import { toast } from "react-toastify";
import MouseOverPopover from "../Popover";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const filter = createFilterOptions();

// Custom styles for the dialog
const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "80%", // Set width to 50% of the screen
    height: "100vh", // Set height to 100% of the screen
    position: "absolute",
    right: 0, // Position the dialog on the right side of the screen
    top: "3%",
    margin: 0,
    borderRadius: 0, // Remove border radius to make it fit the edge
  },
}));

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#99CCF3",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E6",
  700: "#0059B3",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Listbox = styled("ul")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 200px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  box-shadow: 0px 4px 30px ${
    theme.palette.mode === "dark" ? grey[900] : grey[200]
  };
  z-index: 1;

  .closed & {
    opacity: 0;
    transform: scale(0.95, 0.8);
    transition: opacity 200ms ease-in, transform 200ms ease-in;
  }
  
  .open & {
    opacity: 1;
    transform: scale(1, 1);
    transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
  }

  .placement-top & {
    transform-origin: bottom;
  }

  .placement-bottom & {
    transform-origin: top;
  }
  `
);

const AnimatedListbox = React.forwardRef(function AnimatedListbox(props, ref) {
  const { ownerState, ...other } = props;
  const popupContext = React.useContext(PopupContext);

  if (popupContext == null) {
    throw new Error(
      "The `AnimatedListbox` component cannot be rendered outside a `Popup` component"
    );
  }

  const verticalPlacement = popupContext.placement.split("-")[0];

  return (
    <CssTransition
      className={`placement-${verticalPlacement}`}
      enterClassName="open"
      exitClassName="closed"
    >
      <Listbox {...other} ref={ref} />
    </CssTransition>
  );
});

AnimatedListbox.propTypes = {
  ownerState: PropTypes.object.isRequired,
};

const MenuItem = styled(BaseMenuItem)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  user-select: none;

  &:last-of-type {
    border-bottom: none;
  }

  &:focus {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${menuItemClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }
  `
);

const MenuButton = styled(BaseMenuButton)(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  &:hover {
    background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
  }

  &:active {
    background: ${theme.palette.mode === "dark" ? grey[700] : grey[100]};
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${
      theme.palette.mode === "dark" ? blue[300] : blue[200]
    };
    outline: none;
  }
  `
);

const initPendency = {
  pendancy_name: "",
  done_by_sales_agent: false,
  isApproved: null,
  createdAt: null,
  updatedAt: null,
  isEdit: true,
};

export default function ApplicationDetailsDialog({
  open,
  handleClose,
  applicationDetails,
  usersList,
}) {
  const [creditChecker, setCreditChecker] = useState(null);
  const [comment, setComment] = useState("");
  const [pendancies, setPendancies] = useState([]);
  const [comments, setComments] = useState([]);

  const socket = useSocket();

  const { user } = useContext(UserContext);

  const navigate = useNavigate();

  function getAvatarName(name) {
    const avatar_name_arr = name?.split(" ");
    return (
      avatar_name_arr?.[0].charAt(0)?.toUpperCase() +
      (avatar_name_arr?.[1]?.charAt(0)?.toUpperCase() ?? "")
    );
  }

  function handleAddOrEditPendancy(pendancy_name, obj, index) {
    if (!pendancy_name) return;

    if (!obj?.id) {
      let isExist = pendancies.findIndex(
        (itm) =>
          itm?.pendancy_name?.trim()?.toLowerCase() ===
          pendancy_name?.trim()?.toLowerCase()
      );
      if (isExist !== -1) {
        toast.error("pendancy already exist!!!");
        setPendancies(pendancies.filter((_, idx) => idx !== index));
        return;
      }
      obj["createdAt"] = new Date().getTime();
    }

    delete obj["isEdit"];

    let payload = {
      application_id: applicationDetails?.application_id,
      pendancyData: {
        ...obj,
        pendancy_name: pendancy_name?.trim(),
        updatedAt: new Date().getTime(),
      },
    };
    socket.emit("update_pendancy_details", payload);
    setPendancies((prev) => {
      delete prev[index].isEdit;
      return prev.filter((itm) => !!itm?.pendancy_name);
    });
  }

  function handlePendancyDoneBySalesAgent(obj, index) {
    delete obj["isEdit"];

    let payload = {
      application_id: applicationDetails?.application_id,
      pendancyData: {
        ...obj,
        done_by_sales_agent: !Boolean(obj?.done_by_sales_agent),
        updatedAt: new Date().getTime(),
      },
    };
    socket.emit("update_pendancy_details", payload);
    setPendancies((prev) => {
      delete prev[index].isEdit;
      return [...prev];
    });
  }

  function handleDeletePendancy(obj, index) {
    delete obj["isEdit"];

    let payload = {
      application_id: applicationDetails?.application_id,
      pendancyData: obj,
      delete_pendancy: true,
    };
    socket.emit("update_pendancy_details", payload);
  }

  function handlePendancyApproval(isApproved, rejection_reason, obj, index) {
    delete obj["isEdit"];

    let payload = {
      application_id: applicationDetails?.application_id,
      pendancyData: {
        ...obj,
        isApproved: obj["isApproved"] === isApproved ? null : isApproved,
        rejection_reason
      },
    };
    socket.emit("update_pendancy_details", payload);
    setPendancies((prev) => {
      delete prev[index].isEdit;
      return [...prev];
    });
  }

  function handleAddComment() {
    let payload = {
      application_id: applicationDetails?.application_id,
      commentData: {
        comment,
        commented_by: {
          name: user.name,
          email: user.email,
        },
        createdAt: new Date().getTime(),
        updatedAt: new Date().getTime(),
      },
    };

    socket.emit("update_pendancy_comment", payload);
  }

  function handleEditComment(text, obj, index) {
    let payload = {
      application_id: applicationDetails?.application_id,
      commentData: {
        ...obj,
        comment: text,
        commented_by: {
          name: user.name,
          email: user.email,
        },
        updatedAt: new Date().getTime(),
      },
    };
    socket.emit("update_pendancy_comment", payload);
  }

  function handleDeleteComment(obj, index) {
    let payload = {
      application_id: applicationDetails?.application_id,
      commentData: obj,
      delete_comment: true,
    };
    socket.emit("update_pendancy_comment", payload);
  }

  function handleGetData(data) {
    const { pendancies = [], comments = [] } = data?.result || {};
    setComments(comments);
    setPendancies(pendancies);
    setComment("");
  }

  function handleAssignCC(data) {
    const {
      data: {
        userData: { email },
      },
    } = data;
    setCreditChecker(
      usersList?.CREDIT?.filter((itm) => itm?.email === email)?.[0] ?? null
    );
  }

  function handleChangeStage(stage_name) {
    try {
      if (!stage_name) return;

      const stage_value = {
        ready_to_credit_check: true,
        ready_for_disbursal: true,
        sales_agent: {
          email:
            applicationDetails?.additional_stages?.sales_agent?.[0]?.email ??
            user?.email,
        },
        credit_checker: {
          email:
            applicationDetails?.additional_stages?.credit_checker?.[0]?.email ??
            user?.email,
        },
      };

      let updateData = {
        application_id: applicationDetails?.application_id,
        [stage_name]: stage_value[stage_name],
      };

      let applicationData = {
        ...applicationDetails,
        moved_stage: stage_name,
      };

      socket.emit("update_add_stage", {
        applicationData,
        updateData,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
    }
  }

  function handleEvents() {
    socket.on("get_pendancy_data", handleGetData);
    socket.on("update_pendancy_details", handleGetData);
    socket.on("update_pendancy_comment", handleGetData);
    socket.on("assign_credit_checker", handleAssignCC);
  }

  function handleInvolkEvents() {
    socket.emit("get_pendancy_data", {
      application_id: applicationDetails?.application_id,
    });
  }

  useEffect(() => {
    if (open && socket) {
      handleEvents();
      handleInvolkEvents();
    }
  }, [socket, open]);

  useEffect(() => {
    if (open) {
      const cc_email =
        applicationDetails?.additional_stages?.credit_checker?.[0]?.email;
      setCreditChecker(
        usersList?.CREDIT?.filter((itm) => itm?.email === cc_email)?.[0] ?? null
      );
      setPendancies([]);
      setComments([]);
      setComment("");
    }
  }, [open]);

  return (
    <React.Fragment>
      <CustomDialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            overflow: "visible", // Allow the dropdown to overflow
          },
        }}
      >
        <AppBar
          variant="outlined"
          color="transparent"
          sx={{ position: "relative" }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              onClick={() => {
                navigate(
                  `/view-application/${applicationDetails?.application_id}/2`
                );
              }}
              sx={{
                ml: 2,
                flex: 1,
                ":hover": { color: "#2196f3" },
                cursor: "pointer",
              }}
              variant="h6"
              component="div"
            >
              {applicationDetails?.application_id}{" "}
              <span className="fs-6">
                ({applicationDetails?.customer_name})
              </span>
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid
          container
          spacing={2}
          px={3}
          py={3}
          overflow={"scroll"}
          maxHeight={"65dvh"}
        >
          <Grid container item xs={12} sm={10}>
            <Grid
              item
              xs={12}
              sm={4}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <Typography variant="button" className="text-start w-100">
                Credit Checker :
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="button" gutterBottom>
                <Autocomplete
                  value={creditChecker}
                  onChange={(event, newValue) => {
                    setCreditChecker(newValue);
                    socket.emit("assign_credit_checker", {
                      application_id: applicationDetails?.application_id,
                      userData: { email: newValue.email },
                    });
                  }}
                  size="small"
                  filterOptions={(options, params) => filter(options, params)}
                  selectOnFocus
                  clearOnBlur
                  disableClearable
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={usersList?.CREDIT}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => {
                    const { key, ...optionProps } = props;
                    const avatar_name = getAvatarName(option?.name);
                    return (
                      <li key={key} {...optionProps}>
                        <span className="d-flex flex-row align-items-center">
                          <SmallAvatar sx={{ width: 30, height: 30 }}>
                            {avatar_name}
                          </SmallAvatar>
                          <span className="ms-2">
                            {capitalize(option?.name)}
                          </span>
                        </span>
                      </li>
                    );
                  }}
                  sx={{ width: 300 }}
                  freeSolo
                  renderInput={(params) => <TextField {...params} />}
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Divider variant="outlined" component={"hr"} />
          </Grid>
          <Grid container item xs={12} sm={12}>
            {/* pendancy list */}
            <Grid
              item
              xs={12}
              sm={4}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <Typography
                variant="button"
                className="text-start w-100"
                gutterBottom
              >
                Move File To :
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              {applicationDetails?.stage !== "sales_agent" && (
                <Button
                  variant="outlined"
                  className="rounded me-2"
                  size="small"
                  onClick={() => {
                    handleChangeStage("sales_agent");
                  }}
                >
                  Login
                </Button>
              )}
              {user?.departments?.includes("SALES") &&
                applicationDetails?.stage !== "ready_for_credit_check" && (
                  <Button
                    variant="outlined"
                    className="rounded me-2"
                    size="small"
                    onClick={() => {
                      handleChangeStage("ready_for_credit_check");
                    }}
                  >
                    Ready For CC
                  </Button>
                )}
              {user?.departments?.includes("CREDIT_CHECK") &&
                applicationDetails?.stage !== "credit_checker" && (
                  <Button
                    variant="outlined"
                    className="rounded me-2"
                    size="small"
                    onClick={() => {
                      handleChangeStage("credit_checker");
                    }}
                  >
                    Credit Checker
                  </Button>
                )}
              {user?.departments?.includes("CREDIT_CHECK") &&
                applicationDetails?.stage !== "ready_for_disbursal" && (
                  <Button
                    variant="outlined"
                    className="rounded me-2"
                    size="small"
                    onClick={() => {
                      handleChangeStage("ready_for_disbursal");
                    }}
                  >
                    Ready For Disbursal
                  </Button>
                )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Divider role="presentation" />
          </Grid>
          <Grid container item xs={12} sm={12}>
            {/* pendancy list */}
            <Grid
              item
              xs={12}
              sm={4}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <Typography
                variant="button"
                className="text-start w-100"
                gutterBottom
              >
                Pendancies :
              </Typography>
            </Grid>
            {pendancies?.length > 0 ? (
              pendancies.map((item, idx) => {
                return (
                  <Grid item xs={12} sm={12} key={item?.id}>
                    <PendancyComponent
                      key={item?.id}
                      {...item}
                      handleAddOrEditPendancy={(value) => {
                        handleAddOrEditPendancy(value, item, idx);
                      }}
                      handleDeletePendancy={() =>
                        handleDeletePendancy(item, idx)
                      }
                      handleCancelEdit={() => {
                        setPendancies((prev) => {
                          if (prev[idx].id) {
                            prev[idx].isEdit = false;
                            return [...prev];
                          }
                          return prev.filter((_, i) => i !== idx);
                        });
                      }}
                      handlePendancyApproval={(isApproved,rejection_reason) =>
                        handlePendancyApproval(isApproved,rejection_reason, item, idx)
                      }
                      handlePendancyDoneBySalesAgent={() =>
                        handlePendancyDoneBySalesAgent(item, idx)
                      }
                    />
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12} sm={12}>
                <NoData text="No Pendancies" className="bg-success" />
              </Grid>
            )}
            <Grid item xs={12} sm={12}>
              <Button
                variant="outlined"
                className="rounded"
                onClick={() => {
                  if (
                    pendancies.length > 0 &&
                    !pendancies[pendancies.length - 1].pendancy_name
                  ) {
                    return;
                  }
                  setPendancies((prev) => [...prev, { ...initPendency }]);
                }}
              >
                <Add /> Add Pendency
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={10}>
            <Divider role="presentation" />
          </Grid>
          {/* comments */}
          <Grid
            item
            xs={12}
            sm={4}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <Typography
              variant="button"
              className="text-start w-100"
              gutterBottom
            >
              Comments :
            </Typography>
          </Grid>
          {comments?.length > 0 ? (
            comments?.map((item, index) => {
              return (
                <Grid item xs={12} sm={12}>
                  <CommentComponent
                    {...item}
                    handleDeleteComment={() => handleDeleteComment(item, index)}
                    isEditable={user.email === item?.commented_by?.email}
                    handleEditComment={(comment) =>
                      handleEditComment(comment, item, index)
                    }
                  />
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12} sm={12}>
              <NoData text="No Comments" className="bg-secondary" />
            </Grid>
          )}
        </Grid>
        <Paper
          variant="outlined"
          className="position-absolute bottom-0 end-0 w-100"
          style={{ height: 200 }}
        >
          <Divider variant="fullWidth" />
          <Grid container spacing={1} px={3} py={3}>
            <Grid
              item
              xs={12}
              sm={2}
              className="d-flex flex-column align-items-center justify-content-end"
            >
              <Avatar variant="circular" sx={{ bgcolor: "#2196f3" }}>
                {getAvatarName(user?.name)}
              </Avatar>
            </Grid>
            <Grid item xs={12} sm={10}>
              <Typography variant="button">
                <TextField
                  rows={3}
                  fullWidth
                  inputMode="text"
                  multiline
                  placeholder="comment"
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleAddComment();
                    }
                  }}
                />
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              className="d-flex flex-column align-items-center justify-content-end"
            ></Grid>
            <Grid
              item
              xs={12}
              sm={10}
              className="d-flex flex-row align-items-center justify-content-end"
            >
              <Button
                disableElevation
                disabled={!Boolean(comment)}
                variant="outlined"
                onClick={() => {
                  handleAddComment(comment);
                  setComment("");
                }}
              >
                Comment
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </CustomDialog>
    </React.Fragment>
  );
}

const PendancyComponent = ({
  pendancy_name = "",
  done_by_sales_agent = false,
  isApproved = null,
  isEdit = false,
  rejection_reason=null,
  handlePendancyApproval,
  handlePendancyDoneBySalesAgent,
  handleAddOrEditPendancy,
  handleCancelEdit,
  handleDeletePendancy,
}) => {
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState("");
  const [isRejected, setIsRejected] = useState(false);
  const [rejectReason, setRejectReason] = useState("");

  function handleEdit() {
    if (isEdit) {
      handleCancelEdit();
    } else {
      setEdit((prev) => !prev);
    }
  }

  const createHandleMenuClick = (menuItem) => {
    return () => {
      switch (menuItem) {
        case "delete":
          handleDeletePendancy();
        case "edit":
          handleEdit();
        case "reject":
          setIsRejected(false);
      }
    };
  };

  useEffect(() => {
    setEdit(isEdit);
  }, [isEdit]);

  useEffect(() => {
    setText(pendancy_name);
  }, [pendancy_name]);

  return (
    <Paper
      variant="outlined"
      className={`p-2 my-2 d-flex flex-row align-items-center justify-content-between ${
        isApproved === false && "bg-danger"
      } ${isApproved === true && "bg-success"}`}
    >
      <div
        className={`d-flex flex-row align-items-center justify-content-start ${
          isApproved === false && "bg-danger"
        }`}
      >
        {!edit && !isRejected && (
          <>
            {isApproved === null && (
              <IconButton
                size="small"
                className={`border me-2 ${done_by_sales_agent && "bg-success"}`}
                onClick={() => handlePendancyDoneBySalesAgent()}
              >
                <Check
                  fontSize="small"
                  className={done_by_sales_agent && "text-white"}
                />
              </IconButton>
            )}
            <Typography
              bgcolor={"AppWorkspace"}
              variant="p"
              textAlign={"center"}
              fontSize={"16px"}
              className={`${isApproved === false && "bg-danger text-white"} ${
                isApproved === true && "bg-success text-white"
              }`}
            >
              {pendancy_name}
            </Typography>
          </>
        )}
        {!isRejected && edit && (
          <Typography fontSize={"16px"}>
            <input
              type="text"
              style={{ width: "450px" }}
              value={text}
              onChange={(e) => setText(e.target.value)}
              onKeyDown={(e) => {
                if (!e.shiftKey && e.key === "Enter") {
                  e.preventDefault();
                  handleAddOrEditPendancy(e.currentTarget.value);
                  setEdit(false);
                }
              }}
            />
          </Typography>
        )}
        {isRejected && (
          <>
            <Typography marginTop={0} fontSize={"16px"}>
              <span style={{ fontSize: 12 }}>Rejection Reason for {pendancy_name}:</span>
              <span className="d-flex flex-row align-items-center justify-content-between">
                <input
                  type="text"
                  style={{ width: "450px" }}
                  value={rejectReason}
                  onChange={(e) => setRejectReason(e.target.value)}
                  onKeyDown={(e) => {
                    if (!e.shiftKey && e.key === "Enter") {
                      e.preventDefault();
                      handlePendancyApproval(false,e.target.value);
                      setRejectReason("");
                      setIsRejected(false);
                    }
                  }}
                />
                <Button
                  variant="outlined"
                  size="small"
                  color="error"
                  className="ms-1"
                  onClick={createHandleMenuClick("reject")}
                >
                  Cancel
                </Button>
              </span>
            </Typography>
          </>
        )}
      </div>
      <div className="d-flex flex-row align-items-center">
        {!edit && !isRejected && isApproved === null && done_by_sales_agent && (
          <>
            <IconButton
              size="small"
              className={`border mx-1 ${
                isApproved === false && "bg-danger"
              } z-1`}
              // onClick={() => handlePendancyApproval(false)}
              onClick={() => setIsRejected(true)}
            >
              <Clear
                fontSize="small"
                className={isApproved === false && "text-white"}
                color="error"
              />
            </IconButton>
            <IconButton
              size="small"
              className={`border mx-1 ${
                isApproved === true && "bg-success"
              } text-light z-1`}
              onClick={() => handlePendancyApproval(true)}
            >
              <Check
                fontSize="small"
                className={isApproved === true && "text-white"}
                color="success"
              />
            </IconButton>
          </>
        )}
        {!isRejected && isApproved !== null && (
          <Chip
            className="z-6 bg-white mx-1"
            size="small"
            label={isApproved ? "Approved" : "Rejected"}
            color={isApproved ? "success" : "error"}
            variant="outlined"
          />
        )}
        {rejection_reason && <MouseOverPopover className="ms-1" renderUI={<Typography>{rejection_reason}</Typography>} head={<InfoOutlinedIcon fontSize='medium' sx={{fontSize:22, color:"#fff"}} />} />}
        {edit && (
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={createHandleMenuClick("edit")}
          >
            Cancel
          </Button>
        )}
        {!edit && !isRejected && isApproved === null && (
          <Dropdown>
            <MenuButton
              sx={{
                width: 25,
                height: 30,
                borderRadius: "50%", // Makes it rounded
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="rounded-cricle"
            >
              <KeyboardArrowDown fontSize="small" />
            </MenuButton>
            <Menu
              slots={{ listbox: AnimatedListbox }}
              disablePortal
              className="z-3"
            >
              <MenuItem
                sx={{ color: "blue" }}
                onClick={createHandleMenuClick("edit")}
              >
                Edit
              </MenuItem>
              <MenuItem
                sx={{ color: "red" }}
                onClick={createHandleMenuClick("delete")}
              >
                Delete
              </MenuItem>
            </Menu>
          </Dropdown>
        )}
      </div>
    </Paper>
  );
};

const CommentComponent = ({
  commented_by,
  updatedAt,
  comment,
  handleDeleteComment,
  handleEditComment,
  isEditable,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [editComment, setEditComment] = useState("");

  useEffect(() => {
    setEditComment(comment);
  }, [isEdit]);

  return (
    <Paper
      variant="outlined"
      className="p-2 my-2 d-flex flex-column align-items-center"
    >
      <div className="d-flex flex-row align-items-center justify-content-between w-100">
        <div className="d-flex flex-row align-items-center">
          <Typography
            className="fw-semibold"
            textAlign={"start"}
            component={"p"}
          >
            {commented_by?.name ?? ""}
          </Typography>
          <Typography
            className="mx-2"
            fontSize={"10px"}
            textAlign={"start"}
            component={"code"}
          >
            ({moment(updatedAt).format("DD/MM/YYYY hh:mm:ss A")})
          </Typography>
        </div>
        <div>
          {isEditable && !isEdit && (
            <>
              <IconButton
                size={"small"}
                sx={{ marginX: 2 }}
                onClick={() => setIsEdit(true)}
              >
                <Edit fontSize="small" color="primary" />
              </IconButton>
              <IconButton size={"small"} onClick={handleDeleteComment}>
                <Delete fontSize="small" color="error" />
              </IconButton>
            </>
          )}
          {isEditable && isEdit && (
            <Button
              size="small"
              variant="text"
              color="error"
              disableElevation
              onClick={() => setIsEdit(false)}
            >
              Cancel
            </Button>
          )}
        </div>
      </div>
      <Divider />
      <div className="w-100 d-flex flex-row ">
        {!isEdit ? (
          <Typography textAlign={"start"} component={"p"}>
            {comment ?? ""}
          </Typography>
        ) : (
          <TextField
            fullWidth
            multiline
            minRows={3}
            maxRows={12}
            value={editComment}
            onChange={(e) => {
              setEditComment(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleEditComment(editComment);
                setEditComment("");
                setIsEdit(false);
              }
            }}
          />
        )}
      </div>
    </Paper>
  );
};

const NoData = ({ text = "", className = "" }) => {
  return (
    <Paper
      variant="outlined"
      className={`p-2 my-2 d-flex flex-row align-items-center justify-content-between text-white ${className}`}
    >
      <Typography
        className={`w-100 h-100`}
        variant="p"
        textAlign={"center"}
        fontSize={"16px"}
      >
        {text}
      </Typography>
    </Paper>
  );
};

import { CreditCard, Edit } from "@mui/icons-material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArticleIcon from "@mui/icons-material/Article";
import CloseIcon from "@mui/icons-material/Close";
import CommentIcon from "@mui/icons-material/Comment";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import GetAppIcon from "@mui/icons-material/GetApp";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneForwardedOutlinedIcon from "@mui/icons-material/PhoneForwardedOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from '@mui/icons-material/Email';
import {
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Tooltip,
} from "@mui/material";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import BulkCall from "../../Components/BulkCall";
import BulkUploadStatus from "../../Components/BulkUploadStatus";
import CallingHistoryModal from "../../Components/CallingHistoryModal";
import EMIDPDModal from "../../Components/Collection/EmiDpdModal";
import ReconcileModal from "../../Components/Collection/ReconcileModal";
import CommentsModal from "../../Components/Comments/Comments";
import CustomTable from "../../Components/CustomTable/CustomTable";
import DeletePopup from "../../Components/DeletePopup";
import ChatHistory from "../../Components/Dialogs/ChatHistory";
import CommunicationHistory from "../../Components/Dialogs/CommunicationHistory";
import NachPresentsTable from "../../Components/Dialogs/NachPresentsTable";
import DragDrop from "../../Components/DocumentUploader";
import DownloadTable from "../../Components/Download/TableExport";
import AdvancedSort from "../../Components/Sort/AdvancedSort";
import TemplateModal from "../../Components/TemplateModal";
import { OzonetelAgents } from "../../Constants/ozonetelAgents";
import { tags } from "../../Constants/tagsConstants";
import { GlobalContext } from "../../Context/GlobalContext";
import { UserContext } from "../../Context/UserContext";
import {
  AutoCallTwilio,
  assignCollectionExecutive,
  bulkCalling,
  connectToCustomer,
  createTemplate,
  fetchAllAssignee,
  fetchAllCollectionV2,
  fetchAllTemplates,
  fetchCashReciepts,
  fetchSettledCollectionCsv,
  fetchWACountCollection,
  unassignCollectionExecutive,
  updatePaymentBasis,
  uploadAssignCsvV2,
} from "../../api";
import uploadAnimation from "../../assets/cloud-upload.gif";
import { getLatestNach } from "../../hooks/useApplication";
import { f } from "../../utility/Formater";
import PhoneIcon from "@mui/icons-material/Phone";
import BulkMessaging from "../../Components/Dialogs/BulkMessaging";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialStateBulkResponse = {
  successArray: [],
  failedArray: [],
  successCount: 0,
  failedCount: 0,
  open: false,
};

const NachMapping = {
  NA: "Not Available",
  // register_success: "Nach is Registered",
  // register_failed: "Nach registration failed",
  // nack_received: "Nach registration failed",
  // partial: "Nach is Pending",
};

const columnsRemovedForNBFC = {
  Comment: 1,
  "Dealer's Number": 1,
  NBFC: 1,
  "Nach Status": 1,
  Comment: 1,
};

export default function CollectionTableV2() {
  const { user, setUser } = useContext(UserContext);
  const { dpdCollectionState } = useContext(GlobalContext);
  const [dpdCollections, setDPDCollection] = dpdCollectionState;
  const [tableData, setTableData] = useState([]);
  let [commentData, setCommentData] = useState([]);
  const [totalCount, setCount] = useState(0);
  const [isSearch, setSearch] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const [collectionAssigneeList, setCollectionAssigneeList] = useState([]);
  const [anchorStatus, setAnchorStatus] = useState(null);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(null);
  const [isPageLoading, setPageLoading] = useState(false);
  const [filter, setFilter] = React.useState("all");
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);
  const [progress, setProgress] = useState(0);
  const [isUploading, setUploading] = useState(false);
  const [loadTenure, setLoadTenure] = useState(null);
  const [sortButtonValue, setSortButtonValue] = useState("CALLING_VIEW");
  const [index, setIndex] = useState(null);
  const [comment, setComment] = useState([]);
  const navigate = useNavigate();
  const { application_id } = useParams();
  const { state } = useLocation();
  const [callingData, setCallingData] = useState([]);

  const [openFilter, setOpenFilter] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [openStatusOpts, setOpenStatusOpts] = useState(
    localStorage.getItem("overallStatusDays") ? true : false
  );
  const [openNetPayableAmount, setOpenNetPayableAmount] = useState(
    localStorage.getItem("netPayableAmount") ? true : false
  );
  const [applicationData, setApplicationData] = useState({});
  const [AddDisabled, setAddDisabled] = useState(true);
  const [openReconcile, setOpenReconcile] = useState(false);
  const [selectedOption, setSelected] = useState("");
  const [assignCSV, setAssignCSV] = useState(null);
  const [upload, setUpload] = useState(null);
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [bulkUploadResponse, setBulkUploadResponse] = useState(
    initialStateBulkResponse
  );

  const [openExport, setOpenExport] = useState({
    open: false,
    isOutstanding: false,
  });
  const [openView, setopenView] = useState(false);
  const [openDPD, setOpenDPD] = useState({
    open: false,
    dpdArray: [],
  });

  const [openChatHistory, setOpenChat] = useState({
    open: false,
    mobile_number: "",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [contactDetails, setContactDetails] = useState({});
  const [isContactEnabled, setIsContactEnabled] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [anchorE3, setAnchorE3] = React.useState(null);

  const [tableSort, setTableSort] = React.useState({
    sortField: null,
    sortType: null,
  });
  const [pageChange, setPageChange] = React.useState(null);
  const [sortIcon, setSortIcon] = React.useState(false);
  const [openNachPresentTbl, setOpenNachPresentTbl] = useState(false);
  const [waMsgCount, setWaMsgCount] = useState({});
  const [callCount, setCallCount] = useState({});
  const [callingDataCount, setCallingDataCount] = useState(0);
  const [isOpenCalling, setIsOpenCalling] = useState(false);
  let [customMessage, setCustomMessage] = useState("");
  const [templates, setTemplates] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [isBulkCall, setIsBulkCall] = useState(false);
  const [isTemplateModal, setIsTemplateModal] = useState(false);
  const [template, setTemplate] = useState({
    text: "",
    template_name: "",
  });
  let [newTemplateStatus, setNewTemplateStatus] = useState(false);
  const [cashRctData, setCashRctData] = useState({});
  const [cashRctLoading, setCashRctLoading] = useState(false);
  const [openCallingHistory, setOpenCallingHistory] = useState({
    open: false,
    application_id: "",
  });
  const [totalCalls, setTotalCalls] = useState(0);
  const [communicationDialog, setCommunicationDialog] = useState(false);
  const [communicationData, setCommunicationData] = useState({
    tab: 0,
    callingData: {},
    chatData: {},
  });
  const [tab, setTab] = useState(0);
  const [openUnassignModal, setUnassignModal] = useState(false);
  const openExportMenu = Boolean(anchorEl);

  const [bulkMessage, setBulkMessage] = useState({
    open: false,
  });

  const handleUnassignExecutive = async () => {
    try {
      await unassignCollectionExecutive();
      setUnassignModal(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleClickExportMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseExportMenu = () => {
    setAnchorEl(null);
  };

  const fetchTemplates = async () => {
    const { data } = await fetchAllTemplates();
    setTemplates(data);
  };

  const handleOpenView = (index, appData) => {
    setApplicationData({ index, ...appData });
    setopenView(true);
  };

  const handleCloseView = (val) => {
    setopenView(false);
  };

  const handleOpenDpd = (data) => {
    setOpenDPD({
      open: true,
      ...data,
    });
  };

  const handleCloseDPD = () => {
    setOpenDPD({
      open: false,
    });
  };

  const handleCustomMessage = (message) => {
    setCustomMessage(message);
  };

  const handleCallingModal = () => {
    setCallingDataCount(callingData.length);
    setIsOpenCalling(true);
  };
  const handleTemplateModal = () => {
    setIsTemplateModal(true);
  };

  const handleCalling = async (message, campaign) => {
    try {
      customMessage = message;
      // setIsOpenCalling(true)
      let call_data = [];
      callingData.map((ele) => {
        call_data.push({
          "Customer Name":
            ele?.customerData?.pan_details?.name ||
            ele?.customerData?.corporate_pan_details?.name,
          "EMI Amount": ele?.next_schedule?.nextInstallmentAmount?.toFixed(2),
          "EMI Date": moment(ele?.nextDueDate).format("DD-MM-YYYY"),
          // "Contact number": process.env.NODE_ENV == "development" ? '8851672333' : f(ele?.customerData?.mobile_number),
          "Contact number": "8851672333",
          "Application ID": ele?.application_id,
          "Overall Status": ele?.message || "",
        });
      });
      const send_data = {
        data: call_data,
        campaign_type: campaign,
        custom_message: customMessage,
      };
      // console.log(send_data)
      const val = await AutoCallTwilio(send_data);
      setCustomMessage("");
      setIsOpenCalling(false);
      setCallingData([]);
    } catch (err) {
      console.log(err);
    }
  };

  const handleBulkCallOpen = () => {
    setIsBulkCall(true);
  };

  const handleCallToContact = async () => {
    try {
      setIsContactEnabled(true);
      setContactDetails((prev) => ({ ...prev, open: false }));

      let payload = {
        customer_number: contactDetails?.data?.customerData?.mobile_number + "",
        agent_email: user?.email,
      };

      const { data } = await connectToCustomer(payload);
      toast(data?.message);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsContactEnabled(false);
    }
  };

  const handleGetMessageCount = async () => {
    try {
      const { data } = await fetchWACountCollection();
      setWaMsgCount(data?.data || {});
      setCallCount(data?.call_data || {});
      let mobile = searchParams.get("mobile");
      if (mobile) {
        const mobile_number = mobile;
        const sent_count =
          (data?.call_data || {})?.[mobile_number]?.completed_call_count || 0;
        const not_answered =
          (data?.call_data || {})?.[mobile_number]?.no_answer_call_count || 0;
        const total_call_count = sent_count + not_answered;

        setCommunicationData({
          ...communicationData,
          callingData: {
            ...communicationData?.callingData,
            open: true,
            application_id: state?.application_id,
            picked_call_count: sent_count,
            declined_busy_count: not_answered,
            total_count: total_call_count,
          },
          chatData: {
            open: true,
            mobile_number,
            customer_name: "Loading",
          },
        });
      }
    } catch (error) {
      // toast.error(error?.response?.data?.message);
    }
  };

  const getStyle = (percentage) => {
    let color = "";
    if (percentage === 0) {
      color = "#16980A";
    } else if (percentage < 100) {
      color = "#e9bc1b";
    } else if (percentage < 200) {
      color = "#FF6B00";
    } else {
      color = "#FF2222";
    }
    return color;
  };
  // function getSumOfOutstandingAmount(outstanding_amount){
  //   let sum = Object.values(outstanding_amount || {})?.reduce((sum,ele)=>{
  //     return (sum+ +ele)
  //   },0)
  //   return Number(sum || 0)?.toFixed(2)
  // }

  //
  let agentId = user?.email?.replace(/@.+$/, "") || "";
  let isContactable = OzonetelAgents.some((item) => item?.AgentId === agentId);

  // tableData[i]?.customerData?.mobile_number

  function isValidMobileNo(rowData) {
    if (!rowData?.customerData?.mobile_number) return false;
    if ((rowData?.customerData?.mobile_number + "").length !== 10) return false;
    return true;
  }

  const handleToggle = (value) => () => {
    if (selectAll) {
      setCallingData(tableData);
    }
    const currentIndex = callingData.indexOf(value);
    const newSelectedItems = [...callingData];

    if (currentIndex === -1) {
      newSelectedItems.push(value);
    } else {
      newSelectedItems.splice(currentIndex, 1);
    }
    setCallingData(newSelectedItems);
  };

  const ITEM_HEIGHT = 48;
  let columns = [
    {
      label: "GPS",
      value: "gps",
      render: (i) => {
        return tableData[i]?.gps_activated && tableData[i]?.gps_link ? (
          <a href={tableData?.[i]?.gps_link} target="_blank">
            <LocationOnIcon sx={{ color: "green" }} />
          </a>
        ) : (
          <>
            <LocationOffIcon sx={{ color: "red" }} />
          </>
        );
      },
      width: 50,
      left: 0,
      freeze: true,
    },
    {
      label: "Customer Name",
      value: "customer_name",
      render: (i) => {
        return (
          tableData[i]?.customerData?.pan_details?.name ||
          tableData[i]?.customerData?.corporate_pan_details?.name ||
          "--"
        );
        // return statusChipFunc(tableData[i]);
      },
      isSortable: true,
      width: 150,
      left: 73,
      freeze: true,
    },
    {
      label: "Dealer Info",
      render: (i) => {
        return (
          <div>
            <div>{tableData[i]?.anchorData?.name || "--"}</div>
            <div style={{ fontSize: 12, display: "flex" }} className="my-1">
              <div style={{ width: 100, display: "flex" }}>
                <PhoneIcon
                  className="me-1 "
                  style={{ fontSize: 12, marginTop: 3 }}
                />
                {tableData[i]?.anchorData?.mobile_number || "NA"}
              </div>
              <div className="d-flex">
                <LocationOnIcon
                  className="ms-2"
                  style={{ fontSize: 12, marginTop: 3 }}
                />
                {typeof tableData[i]?.applicationData?.master_city === "object"
                  ? f(tableData[i]?.applicationData?.master_city?.name) || "NA"
                  : tableData[i]?.applicationData?.master_city || "NA"}
              </div>
            </div>
          </div>
        );
      },
      value: "anchorData.name",
      width: 250,
    },
    {
      label: "Co-customer Info",
      render: (i) => {
        return (
          <div>
            <div>{f(tableData[i]?.cocustomerData?.pan_details?.name)}</div>
            <div style={{ fontSize: 10 }}>
              ({tableData[i]?.cocustomerData?.customer_type || "NA"})
            </div>
            <div style={{ width: 100, display: "flex", fontSize: 12 }}>
              <PhoneIcon
                className="me-1 "
                style={{ fontSize: 12, marginTop: 3 }}
              />
              {tableData[i]?.cocustomerData?.mobile_number || "NA"}
            </div>
          </div>
        );
      },
      value: "cocustomerData.pan_details.name",
      width: 220,
    },
    {
      label: "Collection Executive",
      width: 100,
      render: (i) => {
        const isLoading = anchorStatus === i;
        const isUserAdmin = Number(user?.role) === 1;

        return (
          <div
            style={{
              display: "flex",
              flexFlow: "row",
              fontSize: "12px",
              color: "blue",
              marginRight: "5px",
            }}
          >
            {isLoading ? (
              <CircularProgress size={20} />
            ) : (
              <>
                {isUserAdmin && (
                  <Edit
                    sx={{
                      fontSize: "medium",
                      marginTop: "5px",
                      marginRight: "3px",
                    }}
                    style={{
                      pointerEvents: "all",
                      opacity: "0.6",
                      cursor: "pointer",
                    }}
                    onClick={(event) =>
                      handleEditClick(event, tableData[i]?.application_id, i)
                    }
                  />
                )}
                {tableData[i]?.collection_executive_assigned?.name ? (
                  <Tooltip
                    title={tableData[i]?.collection_executive_assigned?.email}
                  >
                    <Avatar
                      alt="Remy Sharp"
                      sx={{
                        width: 30,
                        height: 30,
                        bgcolor: "deepPurple[500]",
                        fontSize: "medium",
                      }}
                    >
                      {tableData[i]?.collection_executive_assigned?.name
                        ?.slice(0, 2)
                        .toUpperCase()}
                    </Avatar>
                  </Tooltip>
                ) : (
                  <p>None Assign</p>
                )}
              </>
            )}
          </div>
        );
      },
    },
    {
      label: "Business Executive",
      width: 80,
      render: (i) => {
        return (
          <div
            style={{
              display: "flex",
              flexFlow: "row",
              fontSize: "12px",
              color: "blue",
              marginRight: "5px",
            }}
          >
            {tableData[i]?.applicationData?.additional_stages?.sales_agent?.[0]
              ?.email ? (
              <Tooltip
                title={
                  tableData[i]?.applicationData?.additional_stages?.sales_agent
                    ?.[0]?.email
                }
              >
                <Avatar
                  alt="Remy Sharp"
                  sx={{
                    width: 30,
                    height: 30,
                    bgcolor: "deepPurple[500]",
                    fontSize: "medium",
                  }}
                >
                  {tableData[
                    i
                  ]?.applicationData?.additional_stages?.sales_agent?.[0]?.email
                    ?.slice(0, 2)
                    .toUpperCase()}
                </Avatar>
              </Tooltip>
            ) : (
              <p>None Assign</p>
            )}
          </div>
        );
      },
    },

    {
      label: "Comment",
      width: 130,
      render: (index) => {
        const hasComment = tableData[index]?.last_comment;
        let _ = hasComment
          ? commentData.push(tableData[index]?.last_comment?.tag)
          : "";
        return (
          <div style={{ display: "flex", flexFlow: "column" }}>
            {hasComment ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    marginTop: "10px",
                    cursor: "pointer",
                    opacity: "0.6",
                  }}
                  onClick={() => handleOpenView(index, tableData[index])}
                >
                  <CommentIcon
                    sx={{ fontSize: "small", color: "blue", margin: "3px" }}
                  />
                  <p style={{ fontSize: "11px" }}>
                    <strong>{tableData[index]?.last_comment?.tag}</strong>
                  </p>
                </div>
                {tableData?.[index]?.followup_date != 0 &&
                  tableData?.[index]?.followup_date && (
                    <p style={{ fontSize: "10px", marginTop: "-15px" }}>
                      <strong>
                        {moment(
                          new Date(tableData?.[index]?.followup_date)
                        ).format("DD/MM/YYYY")}
                      </strong>
                      <small>(followup_date)</small>
                    </p>
                  )}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginTop: "10px",
                  cursor: "pointer",
                  opacity: "0.6",
                }}
                onClick={() => handleOpenView(index, tableData[index])}
              >
                <CommentIcon
                  sx={{ fontSize: "small", color: "blue", margin: "3px" }}
                />
                <p style={{ fontSize: "12px" }}>
                  <strong>Add Comment</strong>
                </p>
              </div>
            )}
          </div>
        );
      },
    },
    {
      label: "Contact number",
      value: "customerData.mobile_number",
      render: (i) => (
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>{f(tableData[i]?.customerData?.mobile_number)}</div>
          {isContactable && isValidMobileNo(tableData[i]) && (
            <Button
              variant="outlined"
              disabled={isContactEnabled}
              sx={{ mx: 1 }}
              onClick={() => {
                setContactDetails({ open: true, data: tableData[i] });
              }}
            >
              <PhoneForwardedOutlinedIcon fontSize="small" />
            </Button>
          )}
        </div>
      ),
      width: isContactable ? 165 : 120,
    },
    {
      label: "Calling history",
      render: (i) => {
        const mobile_number = tableData[i]?.customerData?.mobile_number;
        const sent_count =
          callCount?.[mobile_number]?.completed_call_count || 0;
        const not_answered =
          callCount?.[mobile_number]?.no_answer_call_count || 0;
        const total_call_count = sent_count + not_answered;

        const style = {
          color: "white",
          fontSize: 10,
          borderRadius: 30,
          padding: "2px 5px",
        };
        return (
          <Button
            sx={{ mx: 1, display: "flex", justifyContent: "space-between" }}
            onClick={() => {
              setCommunicationData({
                callingData: {
                  open: true,
                  application_id: tableData[i]?.application_id,
                  picked_call_count: sent_count,
                  declined_busy_count: not_answered,
                  total_count: total_call_count,
                },
                chatData: {
                  open: true,
                  mobile_number,
                  customer_name:
                    tableData[i]?.customerData?.pan_details?.name ||
                    tableData[i]?.customerData?.corporate_pan_details?.name,
                },
              });
              setCommunicationDialog(true);
              setTab(2);
            }}
            disabled={sent_count == 0 && not_answered == 0 ? true : false}
          >
            <PhoneForwardedOutlinedIcon fontSize="small" />
            <span
              style={{
                ...style,
                marginRight: "2px",
                marginLeft: "5px",
                background: sent_count === 0 ? "#bdbdbd" : "green",
              }}
            >
              <ArrowUpwardIcon sx={{ fontSize: 10 }} />
              {sent_count}
            </span>
            <span
              style={{
                ...style,
                background: not_answered === 0 ? "#bdbdbd" : "red",
              }}
            >
              <ArrowDownwardIcon sx={{ fontSize: 10 }} />
              {not_answered}
            </span>
          </Button>
        );
      },
      width: 140,
    },
    {
      label: "Message History",
      render: (i) => {
        const mobile_number = tableData[i]?.customerData?.mobile_number;

        const sent_call_count =
          callCount?.[mobile_number]?.completed_call_count || 0;
        const not_answered =
          callCount?.[mobile_number]?.no_answer_call_count || 0;
        const total_call_count = sent_call_count + not_answered;

        const sent_count = waMsgCount?.[mobile_number]?.sent_count || 0;
        const receive_count = waMsgCount?.[mobile_number]?.receive_count || 0;
        const style = {
          color: "white",
          fontSize: 10,
          borderRadius: 30,
          padding: "2px 5px",
        };
        return (
          <Button
            sx={{ mx: 1 }}
            onClick={() => {
              setCommunicationData({
                tab: 1,
                callingData: {
                  open: true,
                  application_id: tableData[i]?.application_id,
                  picked_call_count: sent_call_count,
                  declined_busy_count: not_answered,
                  total_count: total_call_count,
                },
                chatData: {
                  open: true,
                  mobile_number,
                  customer_name:
                    tableData[i]?.customerData?.pan_details?.name ||
                    tableData[i]?.customerData?.corporate_pan_details?.name,
                },
              });
              setCommunicationDialog(true);
              setTab(1);
            }}
          >
            <WhatsAppIcon fontSize="small" className="me-2" />
            <span
              style={{
                ...style,
                marginRight: "2px",
                background: sent_count === 0 ? "#bdbdbd" : "green",
              }}
            >
              <ArrowUpwardIcon sx={{ fontSize: 10 }} />
              {sent_count}
            </span>
            <span
              style={{
                ...style,
                background: receive_count === 0 ? "#bdbdbd" : "green",
              }}
            >
              <ArrowDownwardIcon sx={{ fontSize: 10 }} />
              {receive_count}
            </span>
          </Button>
        );
      },
      width: 140,
    },

    // {
    //   label: "AutoCall Twilio",
    //   // label:
    //   //   (<>
    //   //     <FormGroup sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
    //   //       AutoCall Twilio
    //   //       <Checkbox checked={selectAll} value={tableData} onSelect={() => {
    //   //         setCallingData(tableData)
    //   //         setSelectAll(true)
    //   //       }} onChange={() => setSelectAll(!selectAll)} />
    //   //     </FormGroup>
    //   //   </>
    //   //   )
    //   // ,
    //   render: (i) => (<FormGroup sx={{ alignItems: "center", paddingRight: "50%" }}>
    //     <Checkbox checked={callingData.indexOf(tableData[i]) !== -1} value={tableData[i]} onChange={handleToggle(tableData[i])}
    //     />
    //   </FormGroup>),

    //   width: 140,
    // },
    {
      label: "NBFC",
      render: (i) => {
        return tableData[i]?.nbfcData?.name.split(" ")[0] || "--";
      },
      value: "nbfcData.name",
      width: 80,
    },

    {
      label: "NACH %",
      render: (i) => {
        let nachData = getLatestNach(
          tableData[i]?.physical_nach_data,
          tableData[i]?.enach_data
        )?.latest?.bank_details?.state;
        return tableData[i]?.percentageOfNACH != 0 ? (
          <>{Math.ceil(tableData[i]?.percentageOfNACH) || 0}%</>
        ) : (
          <div>
            <Tooltip title={nachData}>
              <span>0%</span>
            </Tooltip>
          </div>
        );
      },
      value: "percentageOfNACH",
      isSortable: true,
      width: 80,
    },

    {
      label: "Profile %",
      render: (i) => {
        return (
          <>
            <Button
              variant="contained"
              style={{
                width: 100,
                fontSize: 12,
                background: getStyle(
                  tableData?.[i]?.customer_profile_percentage
                ),
                color: "white",
                fontWeight: 400,
              }}
              onClick={() => handleOpenDpd(tableData?.[i])}
            >
              {tableData?.[i]?.customer_profile_percentage} %
            </Button>
          </>
        );
      },
      value: "nbfcData.name",
      width: 100,
    },

    // {
    //   label: "Tenure of collection",
    //   render: (i) => {
    //     return loadTenure === i ? (
    //       <CircularProgress size={20} />
    //     ) : (
    //       f("EMI")
    //       // <Dropdown
    //       //   value={tableData[i]?.payment_basis_mode}
    //       //   ddList={["EMI"]}
    //       //   isNormal={true}
    //       //   onChange={(val) => {
    //       //     changeTenure(tableData[i]?.application_id, val, i);
    //       //   }}
    //       // />
    //     );
    //   },
    //   value: "payment_basis_mode",
    //   width: 150,
    // },

    {
      label: "Net Payable amount as of today",
      render: (i) => {
        return loadTenure === i ? (
          <CircularProgress size={20} />
        ) : tableData[i]?.totalPayable ? (
          f(tableData[i]?.totalPayable?.toFixed(2), "cur")
        ) : (
          "₹ 0.00"
        );
      },
      value: "totalPayable",
      width: 120,
    },
    {
      label: "Overall Status",
      render: (i) => {
        return loadTenure === i ? (
          <CircularProgress size={20} />
        ) : (
          f(tableData[i]?.message)
        );
      },
      value: "message",
      // isSortable: true,
      width: 150,
    },
    // {
    //   label: "Nach Status",
    //   render: (i) => {
    //     let nachData = getLatestNach(
    //       tableData[i]?.physical_nach_data,
    //       tableData[i]?.enach_data
    //     )?.bank_details?.state;
    //     return f(nachData === "NA" ? null : nachData);
    //   },
    //   value: "NachStatus",
    //   width: 120,
    // },
    { label: "Application ID", value: "application_id", copy: true },
    {
      label: "EMI Amount",
      render: (i) => {
        return loadTenure === i ? (
          <CircularProgress size={20} />
        ) : tableData[i]?.next_schedule ? (
          f(
            tableData[i]?.next_schedule?.nextInstallmentAmount?.toFixed(2),
            "cur"
          )
        ) : (
          "₹ 0.00"
        );
      },
      value: "installment_amount",
      width: 160,
    },
    {
      label: "EMI Date",
      render: (i) => {
        return loadTenure === i ? (
          <CircularProgress size={20} />
        ) : tableData[i]?.nextDueDate ? (
          moment(tableData[i]?.nextDueDate).format("DD-MM-YYYY")
        ) : (
          "-"
        );
      },
      value: "nextDueDate",
      width: 120,
    },

    {
      label: "Outstanding Principal Amount",
      render: (i) => {
        return loadTenure === i ? (
          <CircularProgress size={20} />
        ) : (
          // f(getSumOfOutstandingAmount(tableData[i]?.outstanding_amount))
          f(tableData[i]?.outstanding_amount?.outstanding_principal)
        );
      },
      value: "outstanding_amount",
      isSortable: true,
      width: 150,
    },

    {
      label: "txn_last_date",
      render: (i) => {
        return loadTenure === i ? (
          <CircularProgress size={20} />
        ) : tableData[i]?.lastTransactionData?.transaction_dateMS ? (
          moment(tableData[i]?.lastTransactionData?.transaction_dateMS).format(
            "DD-MM-YYYY"
          )
        ) : (
          "-"
        );
      },
      value: "message",
      width: 120,
    },

    {
      label: "txn_last_amount",
      render: (i) => {
        return loadTenure === i ? (
          <CircularProgress size={20} />
        ) : (
          f(tableData[i]?.lastTransactionData?.transaction_amount)
        );
      },
      value: "message",
      width: 120,
    },
    {
      label: "Last Payment mode",
      render: (i) => {
        return loadTenure === i ? (
          <CircularProgress size={20} />
        ) : (
          f(tableData[i]?.lastTransactionData?.payment_mode)
        );
      },
      value: "message",
      width: 120,
    },
    // {
    //   label: "Product Type",
    //   render: (i) => {
    //     return f(tableData[i]?.applicationData?.loan_type || "");
    //   },
    //   value: "applicationData.loan_type",
    //   width: 150,
    // },
  ];

  if (Number(user?.role) === 3) {
    columns = columns.filter((item) => !columnsRemovedForNBFC?.[item?.label]);
  }

  const fetchCollectionExecutive = async () => {
    const val = await fetchAllAssignee(JSON.stringify(["COLLECTIONS"]));
    setCollectionAssigneeList(val?.data?.users?.map((c) => c));
  };

  const fetchTableData = async (
    page,
    limit,
    type,
    typeValue,
    search,
    pageChange
  ) => {
    if (pageChange) setPageLoading(true);
    else setLoading(true);
    try {
      let sort = localStorage.getItem("sort") || sortButtonValue;
      let customSort = JSON.parse(localStorage.getItem("customSort"));

      let params = `&page=${page}`;
      if (limit) params += `&limit=${limit}`;
      if (type === "filter" && typeValue)
        params += `&filters=${encodeURIComponent(
          JSON.stringify(typeValue).toString()
        )}`;
      if (customSort?.sortField)
        params += `&sortField=${customSort?.sortField}&sortType=${customSort?.sortType}`;
      else params += `&sortQuery=${sort}`;
      if (search) params += `&query=${search}`;
      // if (sort === "CALLING_VIEW") params += `&sortQuery=${sort}`;

      let { data } = await fetchAllCollectionV2(params);
      //
      setTableData(data?.data?.[0]?.result?.data || []);
      setCount(data?.data?.[0]?.result?.count);
      localStorage.removeItem("sort");
    } catch (error) {}
    if (pageChange) setPageLoading(false);
    else setLoading(false);
  };
  // useEffect(()=>{
  // const fetchTableData = async (
  //   page,
  //   limit,
  //   type,
  //   typeValue,
  //   sortType,
  //   sortField
  // ) => {
  //   setLoading(true);
  //   try {
  //     let params = `&page=${page}`;
  //     if (limit) params += `&limit=${limit}`;

  //     switch (type) {
  //       case "filter":
  //         params += `&type=filter&filter=${typeValue}`;
  //         break;
  //       case "search":
  //         params += `&type=search&param=${typeValue}`;
  //         break;
  //       case "sort":
  //         if (sortType && sortField)
  //           params += `&type=sort&sortType=${sortType}&sortField=${sortField}`;
  //         break;
  //     }

  //     let { data } = await fetchAllCollection2(params);
  //     setTableData(data?.data?.[0]?.result?.data);
  //     setCount(data?.data?.[0]?.result?.count);
  //   } catch (error) {}
  //   setLoading(false);
  // };

  const initialFetch = () => {
    if (localStorage.getItem("collectionFilterV2")) {
      setFilterApplied(true);
      fetchTableData(
        1,
        50,
        "filter",
        JSON.parse(localStorage.getItem("collectionFilterV2")) || null
      );
    } else {
      fetchTableData(1, 50);
    }
  };

  const handleDPDCollections = () => {
    if (dpdCollections?.length > 0) {
      setTableData(dpdCollections || []);
    } else {
      if (state?.path) {
        navigate(state?.path);
      } else {
        navigate("/collections-v2");
        initialFetch();
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("customSort")) {
      setSortIcon(true);
      fetchTableData(
        page,
        limit,
        "filter",
        JSON.parse(localStorage.getItem("collectionFilterV2")) || null,
        searchTxt ? searchTxt : null,
        pageChange
      );
      setSortButtonValue(null);
    }
  }, [tableSort]);

  useEffect(() => {
    fetchTemplates();
    localStorage.removeItem("customSort");
    fetchCollectionExecutive();
    setOpenStatusOpts(false);
    setOpenNetPayableAmount(false);
    if (searchParams.get("dpd")) {
      handleDPDCollections();
    } else {
      initialFetch();
    }
  }, [upload, isLoadingComments, newTemplateStatus]);
  // useEffect(() => {
  //   // fetchAllComments();
  //   if (!selectedField === null) {
  //     setAddDisabled(false);
  //   }

  //   // else if()
  //
  // }, [selectedField]);

  let timeOut;

  const handleSearch = (value) => {
    if (value !== "") {
      setSearch(true);
      setSearchTxt(value);
      fetchTableData(
        1,
        limit,
        localStorage.getItem("collectionFilterV2") ? "filter" : null,
        JSON.parse(localStorage.getItem("collectionFilterV2")),
        value
      );
    } else {
      setSearchTxt("");
      setSearch(false);
      fetchTableData(
        1,
        limit,
        localStorage.getItem("collectionFilterV2") ? "filter" : null,
        JSON.parse(localStorage.getItem("collectionFilterV2"))
      );
    }
  };

  const changeTenure = async (application_id, payment_basis, index) => {
    try {
      setLoadTenure(index);
      let { data } = await updatePaymentBasis({
        application_id,
        payment_basis,
      });
      if (data?.status) {
        let { data } = await fetchAllCollectionV2(`&query=${application_id}`);
        //
        let allData = [...tableData];
        allData[index] = (data?.data?.[0]?.result?.data || [])[0];
        setTableData(allData);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setLoadTenure(null);
  };

  const handleTableUpdate = async (i, date) => {
    try {
      let array = [...tableData];
      array[i] = {
        ...array[i],
        followup_date: date,
      };
      setTableData(array);
    } catch (error) {
      console.log(error);
      initialFetch();
    }
  };

  const handleExportSettled = async (filter) => {
    setGeneralLoading("EXPORT");

    try {
      let { data } = await fetchSettledCollectionCsv();
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Settled-collection-data.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || error?.message || "Operation failed!"
      );
    }
    setGeneralLoading(null);
    handleCloseExportMenu();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleEditClick = (event, val, idx) => {
    setId(val);
    setIndex(idx);
    setAnchorE3(event.currentTarget);
  };
  const handleEditClose = () => {
    setAnchorE3(null);
  };

  const handleBulkCallingData = async () => {
    if (template.template_name == "Other") {
      const data = {
        data: {
          template_name: campaignName,
          type: "call",
          text: customMessage,
        },
      };
      await createTemplate(data);
    }
    setUploading(true);
    try {
      let payload = {
        type: "base64",
        file: assignCSV,
        custom_message: customMessage,
        campaign_type: campaignName,
      };
      //

      let { data } = await bulkCalling(payload);
      upload ? setUpload(false) : setUpload(true);
      setIsBulkCall(false);
      setAssignCSV(null);
    } catch (error) {
      setAssignCSV(null);
      toast.error(error?.response?.data?.message);
    }
    setUploading(false);
  };

  const handleUploadExecutiveData = async () => {
    setUploading(true);
    try {
      let payload = {
        csv: assignCSV,
      };
      //

      let { data } = await uploadAssignCsvV2(payload, setProgress);

      let tmpSuccessArray = data?.successArr?.map((c) => ({
        index: c?.row_number,
        application_id: c?.message.split(" ").slice(-1)[0],
      }));
      let tmpFailedArr = data?.failedArr?.map((c) => ({
        ...c,
        errMessage: c?.message,
      }));

      setBulkUploadResponse({
        successArray: data?.successArr,
        failedArray: tmpFailedArr,
        successCount: data?.successArr.length,
        failedCount: data?.failedArr.length,
        failedCsv: data?.failedCsvBase64,
        open: true,
      });
      upload ? setUpload(false) : setUpload(true);
      setProgress(0);
      setOpen(false);
      setAssignCSV(null);
    } catch (error) {
      setProgress(0);
      setAssignCSV(null);
      toast.error(error?.response?.data?.message);
    }
    setUploading(false);
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const handleSampleCsv = (sample_type) => {
    let headers =
      sample_type == "BulkUploadAssignee"
        ? [`application_id,email_id`]
        : [
            "Application ID,Customer Name,Contact number,EMI Amount,EMI Date,Overall Status,Dealer's name",
          ];

    downloadFile({
      data: [...headers, []].join("\n"),
      fileName: `Upload-sample-(${moment(new Date()).format("DD-MM-YYYY")})`,
      fileType: "text/csv",
    });
  };
  const handleCommentStateChange = (newValue) => {
    try {
      let val = newValue?.[0];

      const updatedTableData = [...tableData];
      updatedTableData[index] = {
        ...updatedTableData[index],
        last_comment: {
          tag: val?.tag,
          DateMS: val?.DateMS,
        },
      };
      setTableData(updatedTableData);
    } catch (error) {
      toast.error(error);
    } finally {
      isLoadingComments
        ? setIsLoadingComments(false)
        : setIsLoadingComments(true);
    }
  };
  const handleAssignee = async (val) => {
    try {
      setAnchorE3(null);
      setAnchorStatus(index); // Indicate loading state
      const assignmentData = { application_id: id, ...val };
      const response = await assignCollectionExecutive(assignmentData);
      const updatedTableData = [...tableData];
      updatedTableData[index] = {
        ...updatedTableData[index],
        collection_executive_assigned: {
          name: val?.name,
          email: val?.email,
        },
      };
      setTableData(updatedTableData);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setAnchorStatus(null);
    }
  };

  const handleClick = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleApply = (value) => {
    setSortIcon(false);
    localStorage.removeItem("customSort");
    if (value === "CALLING_VIEW") {
      localStorage.removeItem("sort");
      localStorage.setItem("sort", value);
    } else {
      localStorage.removeItem("sort");
      localStorage.setItem("sort", value);
    }
    fetchTableData(
      page,
      limit,
      "filter",
      JSON.parse(localStorage.getItem("collectionFilterV2")) || ""
    );
    setAnchorE2(null);
    setAnchorStatus(value);
  };

  async function getCashReceiptData() {
    try {
      setCashRctLoading(true);
      const params = `page=1&limit=1`;
      const { data } = await fetchCashReciepts(params);
      setCashRctData({
        ...data,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setCashRctLoading(false);
    }
  }

  const handleClose = () => {
    setAnchorE2(null);
  };

  useEffect(() => {
    if (application_id) {
      handleOpenView(null, { application_id });
    }
  }, [application_id]);

  useEffect(() => {
    let mobile = searchParams.get("mobile");
    if (mobile) {
      setCommunicationData({
        tab: state?.application_id,
        callingData: {
          open: true,
          application_id: state?.application_id,
          picked_call_count: "Loading",
          declined_busy_count: "Loading",
          total_count: "Loading",
        },
        chatData: {
          open: true,
          mobile_number: mobile,
          customer_name: "Loading",
        },
      });
      setCommunicationDialog(true);
      setTab(state?.tab);
    }
  }, [searchParams]);

  useEffect(() => {
    localStorage.removeItem("filterDataCollectionV2");
    handleGetMessageCount();
    getCashReceiptData();
  }, []);

  return (
    <>
      <div className="px-3">
        {/* {!isLoading && ( */}
        <div className="d-flex p-3 flex-column-reverse">
          {searchParams.get("dpd") ? (
            <>
              <Chip
                label="Showing DPD Dashboard Collections"
                variant="outlined"
                onDelete={() => {
                  if (state?.path) {
                    navigate(state?.path);
                  } else {
                    navigate("/collections-v2");
                    initialFetch();
                  }
                }}
              />
            </>
          ) : (
            <>
              <div className="d-flex flex-row-reverse">
                {Number(user?.role) !== 3 && (
                  <Button
                    className="zoom-animation mx-2"
                    variant="outlined"
                    sx={{
                      fontSize: 10,
                      textTransform: "capitalize",
                      cursor: "default",
                    }}
                    size="small"
                    color="secondary"
                    onClick={() => {
                      navigate("/cash-receipt-data");
                    }}
                    disabled={cashRctLoading}
                  >
                    {cashRctLoading
                      ? "Money Held By Partners - Loading..."
                      : `Money Held By Partners : ${
                          cashRctData?.total_pending
                            ? f(cashRctData?.total_pending || 0, "cur")
                            : 0
                        }`}
                  </Button>
                )}

                {Number(user?.role) === 1 && (
                  <Button
                    id="basic-button"
                    className="mx-2 me-5 pointer  text-capitalize"
                    size="small"
                    style={{ color: "gray" }}
                    aria-haspopup="true"
                    onClick={() => {
                      setUnassignModal(true);
                    }}
                  >
                    <DeleteSweepIcon fontSize="small" className="me-1" />{" "}
                    Unassign
                  </Button>
                )}
              </div>

              <div className="d-flex flex-row align-items-center justify-content-between mb-3">
                {/* <Tooltip title="Upload Collection Data">
              <span
                className="me-3 pointer"
                style={{ color: "gray" }}
                onClick={() => handleClickOpen()}
              >
                <FileUploadIcon className="me-1" />
                Upload Collection
              </span>
            </Tooltip> */}

                {/* {Number(user?.role) === 1 && (
                  <Button
                    sx={{ color: "gray" }}
                    onClick={() => setOpenReconcile(true)}
                    disabled={isLoading}
                  >
                    <RefreshIcon />
                    Reconcile Collection
                  </Button>
                )} */}
                <div className="d-flex flex-row align-items-center">
                  <TextField
                    className="search-bar-table"
                    id="input-with-icon-textfield"
                    placeholder="Search"
                    variant="standard"
                    InputProps={{
                      startAdornment: <SearchIcon sx={{ color: "gray" }} />,
                      disableUnderline: true,
                      style: { color: "black", fontWeight: 400, height: 25 },
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch(e.target.value);
                      }
                    }}
                  />
                  {!(
                    filterApplied && localStorage.getItem("collectionFilterV2")
                  ) ? (
                    <Button
                      className="mx-3"
                      sx={{ color: "gray" }}
                      onClick={() => {
                        setFilterApplied(true);
                        setOpenFilter(true);
                      }}
                    >
                      <FilterAltIcon fontSize="small" /> filters
                    </Button>
                  ) : (
                    <>
                      <Button
                        className="ms-3"
                        size="small"
                        onClick={() => {
                          setOpenFilter(true);
                        }}
                      >
                        <span className="zoom-animation">
                          <FilterAltIcon fontSize="small" /> filters
                        </span>
                      </Button>
                      <span
                        className="pointer mx-3"
                        onClick={() => {
                          localStorage.removeItem("collectionFilterV2");
                          localStorage.removeItem("selectDataCollectionV2");
                          localStorage.removeItem("filterDataCollectionV2");
                          localStorage.removeItem("reduceDataCollectionV2");
                          fetchTableData(1, 50, null, null, null, true);
                          setFilterApplied(false);
                          setOpenFilter(false);
                          setOpenStatusOpts(false);
                          setOpenNetPayableAmount(false);
                        }}
                      >
                        <HighlightOffIcon fontSize="small" className="me-1" />
                      </span>
                    </>
                  )}
                </div>
                <div className="d-flex flex-row align-items-center">
                  <Button
                    variant="outlined"
                    className="mx-2 me-3 text-capitalize"
                    size="small"
                    sx={{ color: "grey" }}
                    style={{ borderColor: "grey" }}
                    onClick={handleClick}
                  >
                    {sortButtonValue}
                    <TuneIcon
                      fontSize="small"
                      sx={{ fontSize: 16, marginLeft: 0.5 }}
                    />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorE2}
                    open={Boolean(anchorE2)}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <div
                      style={{
                        padding: "5px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        marginTop: "5px",
                        fontSize: "15px",
                      }}
                    >
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={sortButtonValue}
                        onChange={(e) => {
                          setSortButtonValue(e.target.value);
                          handleApply(e.target.value);
                        }}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="DPD_HIGH_TO_LOW"
                          control={<Radio />}
                          label="DPD High to low"
                        />
                        <FormControlLabel
                          value="CALLING_VIEW"
                          control={<Radio />}
                          label="Calling View"
                        />
                      </RadioGroup>
                      <Divider
                        orientation="Horizontal"
                        style={{
                          height: "2px",
                          backgroundColor: "black",
                          marginTop: "5px",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          margin: "10px",
                          justifyContent: "center",
                        }}
                      >
                        {/* <Button
                          variant="outlined"
                          sx={{ color: "grey" }}
                          style={{ borderColor: "grey" }}
                          onClick={handleClose}
                        >Cancel
                        </Button>
                        <div style={{ margin: "10px" }}></div>
                        <Button
                          variant="contained"
                          onClick={() => { handleApply(sortButtonValue) }}
                        >Apply

                        </Button> */}
                      </div>
                    </div>
                  </Menu>
                  {Number(user?.role) !== 3 && (
                    <Button
                      id="basic-button"
                      className="mx-2 text-capitalize"
                      size="small"
                      style={{ color: "gray" }}
                      aria-haspopup="true"
                      onClick={() => {
                        setOpenNachPresentTbl(true);
                      }}
                    >
                      <CreditCard fontSize="small" className="me-1" /> Nach
                    </Button>
                  )}
                  {/* {Number(user?.role) !== 3 && (
                    <Tooltip title="Auto Call Using twilio">
                      <Button
                        className="me-3 pointer"
                        style={{ color: "gray" }}
                        onClick={() => handleCallingModal()}
                        disabled={callingData.length > 0 ? false : true}
                      >
                        <CallIcon className="me-1" />
                        Auto Call
                      </Button>
                    </Tooltip>
                  )} */}
                  {Number(user?.role) !== 3 && (
                    <Tooltip title="create Template">
                      <Button
                        style={{ color: "gray" }}
                        className="mx-2 text-capitalize"
                        size="small"
                        onClick={() => handleTemplateModal()}
                      >
                        <ArticleIcon fontSize="small" className="me-1" />
                        Template
                      </Button>
                    </Tooltip>
                  )}
                  {Number(user?.role) !== 3 && (
                    <Tooltip title="Upload Collection Data">
                      <Button
                        style={{ color: "gray" }}
                        className="mx-2 text-capitalize"
                        size="small"
                        onClick={() => handleClickOpen()}
                      >
                        <FileUploadIcon fontSize="small" className="me-1" />
                        Bulk Assignee
                      </Button>
                    </Tooltip>
                  )}
                  {Number(user?.role) !== 3 && (
                    <Tooltip title="Upload Data for Bulk Calling">
                      <Button
                        style={{ color: "gray" }}
                        className="mx-2 text-capitalize"
                        size="small"
                        onClick={() => handleBulkCallOpen()}
                      >
                        <PhoneOutlinedIcon fontSize="small" className="me-1" />
                        Bulk Calling
                      </Button>
                    </Tooltip>
                  )}
                  {Number(user?.role) !== 3 && (
                    <Tooltip title="Upload Data for Bulk Message">
                      <Button
                        style={{ color: "gray" }}
                        className="mx-2 text-capitalize"
                        size="small"
                        onClick={() => {
                          setBulkMessage({ open: true });
                        }}
                      >
                        <EmailIcon fontSize="small" className="me-1" />
                        Bulk message
                      </Button>
                    </Tooltip>
                  )}
                  <Tooltip title="Export Collection Data">
                    <Button
                      style={{ color: "gray" }}
                      className="mx-2 text-capitalize"
                      size="small"
                      onClick={handleClickExportMenu}
                      disabled={isLoading}
                    >
                      <GetAppIcon fontSize="small" className="me-1" />
                      Export
                    </Button>
                  </Tooltip>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openExportMenu}
                    onClose={handleCloseExportMenu}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      divider
                      onClick={() => {
                        handleCloseExportMenu();
                        setOpenExport({
                          open: true,
                          isOutstanding: false,
                        });
                      }}
                    >
                      Collection Data
                    </MenuItem>
                    <MenuItem
                      divider
                      onClick={() => {
                        handleCloseExportMenu();
                        setOpenExport({
                          open: true,
                          isOutstanding: true,
                        });
                      }}
                    >
                      Outstanding Amount
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleExportSettled();
                      }}
                      disabled={generalLoading === "EXPORT"}
                    >
                      {generalLoading === "EXPORT"
                        ? "Downloading..."
                        : "Settled Collection Data"}
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </>
          )}
        </div>
        {/* )} */}
        <CustomTable
          tableData={tableData}
          tableHeight={"75vh"}
          columns={columns}
          defaultLimit={50}
          totalCount={totalCount}
          // serialNo={true}
          stickSL={true}
          isLoading={isLoading}
          allowAll={true}
          pageLoading={isPageLoading}
          sortIcon={sortIcon}
          onPageChange={(page, limit) => {
            setLimit(limit);
            setPage(page);
            // if (isSearch) {
            //   fetchTableData(page, limit, "search", searchTxt);
            // } else {
            //   if (filter === "all") {
            //     fetchTableData(page, limit);
            //   } else {
            //     fetchTableData(page, limit, "filter", filter);
            //   }
            // }

            fetchTableData(
              page,
              limit,
              localStorage.getItem("collectionFilterV2") ? "filter" : null,
              localStorage.getItem("collectionFilterV2")
                ? JSON.parse(localStorage.getItem("collectionFilterV2"))
                : null,
              isSearch ? searchTxt : null,
              pageChange
            );
          }}
          onLimitChange={(page, limit) => {
            setLimit(limit);
            setPage(page);
            fetchTableData(
              page,
              limit,
              localStorage.getItem("collectionFilterV2") ? "filter" : null,
              localStorage.getItem("collectionFilterV2")
                ? JSON.parse(localStorage.getItem("collectionFilterV2"))
                : null,
              isSearch ? searchTxt : null
            );
          }}
          onSortChange={(page, limit, sortValue, sortField) => {
            localStorage.setItem(
              "customSort",
              JSON.stringify({ sortField, sortType: sortValue })
            );
            setTableSort({ sortField, sortType: sortValue });
            setPage(page);
            setLimit(limit || 50);
            // fetchTableData(
            //   page,
            //   limit,
            //   "sort",
            //   sortValue,
            //   sortValue,
            //   sortField
            // );
          }}
          onDoubleClick={(data) => {
            navigate(`/view-application/${data?.application_id}/5`, {
              state: {
                path: `collections-v2`,
              },
            });
          }}
        />
      </div>

      {filterApplied && (
        <AdvancedSort
          name="CollectionV2"
          open={openFilter}
          tags={tags}
          embifi_users={collectionAssigneeList}
          last_comments={[...new Set(commentData)]}
          setOpenStatusOpts={setOpenStatusOpts}
          setOpenNetPayableAmount={setOpenNetPayableAmount}
          openStatusOpts={openStatusOpts}
          openNetPayableAmount={openNetPayableAmount}
          handleClose={() => {
            setOpenFilter(false);
          }}
          onApply={(filter) => {
            //
            localStorage.setItem("collectionFilterV2", JSON.stringify(filter));
            fetchTableData(1, limit, "filter", filter, null, true);
            setOpenFilter(false);
          }}
          options={(() => {
            if (Number(user?.role) === 3) {
              return [
                "application_id",
                "customer_name",
                "anchor",
                "oem",
                "due_date",
                "overall_status",
                "net_payable_amount",
                "comments",
                "comments_date",
                "followup_date",
                "customer_profile_percentage",
                "delayed_threshold",
                "old_dpd",
              ];
            } else {
              return [
                "application_id",
                "customer_name",
                "anchor",
                "nbfc",
                "oem",
                "due_date",
                "physical_nach_status",
                "overall_status",
                "net_payable_amount",
                "comments",
                "comments_date",
                "followup_date",
                "customer_profile_percentage",
                "delayed_threshold",
                "old_dpd",
                "collection_executive",
                "last_comment",
                "business_executive",
              ];
            }
          })()}
        />
      )}

      <ReconcileModal
        openReconcile={openReconcile}
        setOpenReconcile={setOpenReconcile}
        fetchTableData={fetchTableData}
      />

      <CommentsModal
        openView={openView}
        applicationData={applicationData}
        handleCloseView={handleCloseView}
        handleTableUpdate={handleTableUpdate}
        loadComments={handleCommentStateChange}
        defaultType={"COLLECTION"}
      />

      {openDPD?.open && (
        <EMIDPDModal
          data={openDPD}
          handleClose={handleCloseDPD}
          getStylePer={getStyle}
        />
      )}

      <DownloadTable
        open={openExport?.open}
        isOutstanding={openExport?.isOutstanding}
        type={"collections"}
        filterData={localStorage.getItem("collectionFilterV2") || null}
        handleClose={() =>
          setOpenExport({
            open: false,
            isOutstanding: false,
          })
        }
      />

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorE3}
        open={Boolean(anchorE3)}
        onClose={handleEditClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "25ch",
            // boxShadow:'none'
          },
        }}
      >
        {collectionAssigneeList?.map((ele, key) => (
          <>
            <MenuItem
              key={key}
              value={ele}
              onClick={() => {
                handleAssignee(ele, key);
              }}
            >
              {ele?.email}
            </MenuItem>
          </>
        ))}
      </Menu>
      {/*
       */}

      {isTemplateModal && (
        <TemplateModal
          open={isTemplateModal}
          handleClose={() => setIsTemplateModal(!isTemplateModal)}
          templates={templates}
          valupdate={() => setNewTemplateStatus(!newTemplateStatus)}
        />
      )}

      <BulkCall
        open={isBulkCall}
        close={() => setIsBulkCall(false)}
        templates={templates}
        Transition={Transition}
        downloadFile={downloadFile}
      />

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {!isUploading ? (
          <DialogContent>
            {assignCSV === null ? (
              <div className="mb-5">
                <div className="d-flex justify-content-end mb-4">
                  <Button
                    onClick={() => {
                      handleSampleCsv("BulkUploadAssignee");
                    }}
                  >
                    <FileDownloadIcon /> Sample
                  </Button>
                </div>
                <DragDrop
                  customType={["CSV"]}
                  setFile={(val, name) => {
                    setAssignCSV(val);
                  }}
                />
              </div>
            ) : (
              <>
                <div
                  className="mt-2 d-flex"
                  style={{ width: "300px", justifyContent: "space-between" }}
                >
                  <div>
                    <InsertDriveFileIcon /> Executive data
                  </div>
                  <CloseIcon
                    className="pointer"
                    onClick={() => {
                      setAssignCSV(null);
                    }}
                  />
                </div>
              </>
            )}
          </DialogContent>
        ) : (
          <DialogContent>
            <div>
              <p className="text-center">
                <img
                  className="mt-5"
                  src={uploadAnimation}
                  width={200}
                  alt="uploading..."
                />
              </p>
              <ProgressBar
                className="mt-4"
                striped
                variant="success"
                label={`${progress.toFixed(2)}%`}
                now={progress}
              />
            </div>
          </DialogContent>
        )}
        {!isUploading && (
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleUploadExecutiveData}>Submit</Button>
          </DialogActions>
        )}
      </Dialog>
      <Dialog
        open={contactDetails?.open}
        onClose={() => {
          setContactDetails({ open: false, data: {} });
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {`Are you sure want to contact ${contactDetails?.data?.customerData?.mobile_number} ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            The following interactions will be conducted via Ozonetel , By
            pressing "CALL" a phone call will be sent to you , After your call
            receive ,call will be conducted between you and customer
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setContactDetails({ open: false, data: {} });
            }}
          >
            cancel
          </Button>
          <Button onClick={handleCallToContact} autoFocus>
            call
          </Button>
        </DialogActions>
      </Dialog>

      <BulkUploadStatus
        open={bulkUploadResponse?.open}
        successArray={bulkUploadResponse?.successArray}
        failedArray={bulkUploadResponse?.failedArray}
        successCount={bulkUploadResponse?.successCount}
        failedCount={bulkUploadResponse?.failedCount}
        failedCsv={bulkUploadResponse?.failedCsv}
        handleClose={() => {
          setBulkUploadResponse(initialStateBulkResponse);
        }}
      />

      <ChatHistory
        open={openChatHistory?.open}
        mobile_number={openChatHistory?.mobile_number}
        customer_name={openChatHistory?.customer_name}
        handleClose={() => {
          setOpenChat({
            open: false,
            mobile_number: null,
            customer_name: null,
          });
        }}
      />

      <NachPresentsTable
        open={openNachPresentTbl}
        handleClose={() => {
          setOpenNachPresentTbl(false);
        }}
      />

      <CallingHistoryModal
        control={openCallingHistory}
        totalCount={totalCalls}
        handleClose={() => {
          setOpenCallingHistory(false);
        }}
      />

      <CommunicationHistory
        modal={communicationDialog}
        handleClose={() => setCommunicationDialog(false)}
        tab={tab}
        changeTab={() => {
          setTab(tab === 1 ? 2 : 1);
        }}
        chatData={communicationData.chatData}
        callData={communicationData.callingData}
        commData={communicationData}
      />

      <DeletePopup
        confDltPopup={openUnassignModal}
        setConfDltPopup={setUnassignModal}
        handleDeleteDoc={handleUnassignExecutive}
        text={"Are you sure you want to unassign all executive ?"}
      />

      {bulkMessage?.open && (
        <BulkMessaging
          open={true}
          handleClose={() => {
            setBulkMessage({ open: false });
          }}
        />
      )}
    </>
  );
}
